import { useState, useEffect } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
const CreateCompanyForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [isBootcamp, setIsBootcamp] = useState(false);
  const { error, post } = useFetch();
  const { t } = useTranslation("admin");
  const history = useHistory();

  const createCompany = (email, password) => {
    const data = {
      email: email,
      password: password,
      description: description,
      name: name,
      is_bootcamp: isBootcamp,
    };
    post(
      "/admin/companies",
      data,
      history.push("/admin"),
      displaySuccess(t("admin:company_created")),
      displayError(t("admin:company_creation_failed"))
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createCompany(email, password);
  };

  return (
    <div>
      {error && <h4>{error}</h4>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} lg={6}>
            <div>
              <Form.Group>
                <Form.Label>{t("register:placeholderemail")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("register:placeholderemail")}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div>
              <Form.Group>
                <Form.Label>{t("admin:company_name")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("admin:company_name")}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label>{t("register:placeholderpassword")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("register:placeholderpassword")}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label>{t("admin:password_confirmation")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("admin:password_confirmation")}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>{t("admin:description")}</Form.Label>
              <Form.Control
                as="textarea"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Label className="mr-3">
            {t("admin:create_bootcamp_company")} :
          </Form.Label>
          <Col>
            <Form.Check
              inline
              type="checkbox"
              onChange={() => setIsBootcamp(!isBootcamp)}
            />
          </Col>
        </Row>
        <div className="d-flex row justify-content-center">
          <Button
            type="submit"
            className="ButtonPrimary w-50"
            onClick={handleSubmit}
          >
            {t("admin:create_company")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateCompanyForm;
