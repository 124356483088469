import { useEffect, useState } from "react";
import { useParams, Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Nav, Container, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { fetchCurrentUser } from "stores/authentication/authMiddleware";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import LessonVideo from "./LessonVideo/LessonVideo";
import LessonQuizz from "./LessonQuizz/LessonQuizz";
import ButtonPrimary from "components/ButtonPrimary/ButtonPrimary";
import ChapterProgressBar from "./ChapterProgressBar.jsx/ChapterProgressBar";
import LessonRouter from "./LessonRouter";
import "./Lesson.scss";
import useDeviceResize from "hooks/useDeviceResize";
import { verifyIfUserAccountLimitDateAreExpired } from "helpers/verifyIfUserAccountLimitDateAreExpired";
import { displayWarning } from "stores/flashmessages/flashMiddleware";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { IoOpen } from "react-icons/io5";

const Lesson = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { courseId, chapterId, lessonId } = useParams();
  const { t } = useTranslation("lesson");
  const { data, get, error, post, isLoading } = useFetch();
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [videoIsOpen, setVideoIsOpen] = useState(true);
  const [haveToDisplayVideoButton, setHaveToDisplayVideoButton] =
    useState(false);
  const { isMobile, checkOnDeviceResize, removeResizeListener } =
    useDeviceResize();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleStateVideo = () => setVideoIsOpen(!videoIsOpen);

  const history = useHistory();

  const checkIfButtonVideoShouldBeDisplayed = () => {
    if (data && data.video_url !== null && data.video_url !== "") {
      setHaveToDisplayVideoButton(true);
    } else {
      setHaveToDisplayVideoButton(false);
    }
  };

  const handleNextLesson = async () => {
    const sendedQuizzResult = await post(
      `/courses/${courseId}/chapters/${data.chapter.id}/lessons/${data.id}/results`,
      { quizz_result: "0/0" }
    );
    history.push(
      `/courses/${courseId}/chapters/${data.next_lesson.chapter_id}/lessons/${data.next_lesson.id}`
    );
  };

  const redirectAfterFinishedCourse = () => {
    history.push("/profile/dashboard");
  };

  const udpateLessonProgress = () => {
    post(
      `/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/results`,
      null
    );
  };

  const validateLesson = () => {
    post(
      `/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/results`,
      { quizz_result: null },
      redirectAfterFinishedCourse
    );
  };

  const createOrUpdateLessonBookmark = () => {
    const bookmarkToCreate = {
      lesson_bookmark: {
        lesson_id: lessonId,
        course_id: courseId,
      },
    };
    post(
      `/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/lesson_bookmarks`,
      bookmarkToCreate
    );
  };

  const handleEachColumnSizeDependingOnStates = (columnType) => {
    if (columnType === "progressbar") {
      if (isOpen) {
        return 3;
      }
      return 1;
    }

    if (columnType === "lessontext") {
      if (videoIsOpen && isOpen) {
        return 4;
      }
      if (videoIsOpen && !isOpen) {
        return 5;
      }
      if (!videoIsOpen && isOpen) {
        return 8;
      }
      if (!videoIsOpen && !isOpen) {
        return 11;
      }
    }

    if (columnType === "video") {
      if (videoIsOpen && isOpen) {
        return 4;
      }
      if (videoIsOpen && !isOpen) {
        return 5;
      }
      if (!videoIsOpen) {
        return 0;
      }
    }
  };

  useEffect(() => {
    if (currentUser)
      verifyIfUserAccountLimitDateAreExpired(
        currentUser,
        t,
        dispatch,
        displayWarning,
        history
      );
  }, [currentUser]);

  useEffect(() => {
    if (courseId && chapterId && lessonId && currentUser) {
      udpateLessonProgress();
      createOrUpdateLessonBookmark();
    }
  }, [courseId, chapterId, lessonId, currentUser]);

  useEffect(() => {
    get(`/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}`);
    //dispatch(fetchCurrentUser(token));
    checkOnDeviceResize();
  }, [lessonId]);

  useEffect(() => {
    const isVideoAvailable =
      data && data.video_url !== null && data.video_url !== "";
    setVideoIsOpen(isVideoAvailable);
    if (data) {
      checkIfButtonVideoShouldBeDisplayed();
    }
    window.scrollTo(0, 0);
  }, [data]);

  const getProgressLesson = () => {
    return currentUser.progress_lessons.find(
      (progress) => progress.lesson_id === Number(lessonId)
    );
  };

  const handleProgressSideBar = () => {
    setIsOpen(!isOpen);
  };

  removeResizeListener();


  useEffect(() => {
    if (data) {
      console.log(data);
    }
  }, [data]);
  
  return (
    <Container fluid className="Lesson">
      {error && (
        <h1>
          {error}
          <Link to={"/profile/mycourses"}>{t("back_to_profile")}</Link>
        </h1>
      )}

      {!data && isLoading && (
        <div
          className="d-flex flex-row justify-content-center align-items-center w-100"
          style={{height: "100vh" }}
        >
          <div className="spinner-border text-warning" role="status"></div>
        </div>
      )}
      {data && currentUser && (
        <div style={{ height: "100%" }}>
          <Row>
            <Col
              md={isOpen ? 3 : 1}
              sm={12}
              className="mr-4 pl-0 progressWrap"
              style={{ minHeight: `${isMobile ? "0" : "90vh"}` }}
            >
              <ChapterProgressBar
                currentLesson={data}
                onClick={handleClose}
                handleProgressSideBar={handleProgressSideBar}
                isOpen={isOpen}
                isMobile={isMobile}
              />
            </Col>
            <Col
              md={
                isOpen && videoIsOpen
                  ? 4
                  : videoIsOpen && !isOpen
                  ? 5
                  : !videoIsOpen && isOpen
                  ? 6
                  : !videoIsOpen && !isOpen
                  ? 8
                  : null
              }
              className="lessonText"
            >
              <Nav
                defaultActiveKey={`/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/content`}
                className="nav nav-pills nav-justified"
              >
                <Nav.Item>
                  <NavLink
                    className="nav-link"
                    to={`/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/content`}
                  >
                    {t("content")} 📖
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink
                    className="nav-link"
                    to={`/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/forum`}
                  >
                    {t("forum")} 💬
                  </NavLink>
                </Nav.Item>
                {haveToDisplayVideoButton && (
                  <div className="display-video-or-not justify-content-end ml-3">
                    {!videoIsOpen && (
                      <Nav.Item>
                        <button
                          onClick={handleStateVideo}
                          className="video-state-button"
                        >
                          {t("display_video")}
                        </button>
                      </Nav.Item>
                    )}
                    {videoIsOpen && (
                      <Nav.Item>
                        <button
                          onClick={handleStateVideo}
                          className="video-state-button"
                        >
                          {t("hide_video")}
                        </button>
                      </Nav.Item>
                    )}
                  </div>
                )}
              </Nav>

              <LessonRouter
                ids={{
                  course: courseId,
                  chapter: chapterId,
                  lesson: lessonId,
                }}
                data={data}
                currentUser={currentUser}
              />
              {!videoIsOpen && (
                <Col className="text-center">
                  {(!data.questions.length && data.next_lesson && (
                    <button
                      onClick={handleNextLesson}
                      className="lesson-button text-center mb-4"
                    >
                      {t("go_to_next_lesson")}
                    </button>
                  )) ||
                    (!data.questions.length && !data.next_lesson && (
                      <>
                        <p className="text-center">{t("course_is_over")}</p>
                        <button
                          onClick={validateLesson}
                          className="lesson-button text-center mb-4"
                        >
                          {t("validate_back_to_profile")}
                        </button>
                      </>
                    ))}

                  {(data.questions.length > 0 &&
                    (!getProgressLesson() ||
                      getProgressLesson().quizz_result === null) && (
                      <div className="col justify-content-center">
                        <p className="text-center">
                          {t("need_to_answer_quizz")}
                        </p>
                        <button
                          onClick={handleShow}
                          className="lesson-button text-center mb-4"
                        >
                          {t("do_quizz")}
                        </button>
                        <Modal
                          show={show}
                          onHide={handleClose}
                          dialogClassName="quizz-modal"
                        >
                          <Modal.Header closeButton>{data.title}</Modal.Header>
                          <LessonQuizz
                            ids={{
                              course: courseId,
                              chapter: chapterId,
                              lesson: lessonId,
                            }}
                            handleClose={handleClose}
                          />
                        </Modal>
                      </div>
                    )) ||
                    (data.questions.length > 0 &&
                      getProgressLesson() &&
                      getProgressLesson().quizz_result !== null && (
                        <>
                          <div className="justify-content-center">
                            <div className="mr-1">
                              <p className="text-center">
                                {t("already_answered")}
                              </p>
                            </div>
                            <div>
                              <p className="text-center">
                                {t("result_obtained")} :{" "}
                                {getProgressLesson().quizz_result}
                              </p>
                            </div>
                            <div className="text-center">
                              <button
                                onClick={handleShow}
                                className="lesson-button text-center mb-4"
                              >
                                {t("redo_quizz")}
                              </button>
                            </div>

                            <Modal
                              show={show}
                              onHide={handleClose}
                              dialogClassName="quizz-modal"
                            >
                              <Modal.Header closeButton>
                                {data.title}
                              </Modal.Header>
                              <LessonQuizz
                                ids={{
                                  course: courseId,
                                  chapter: chapterId,
                                  lesson: lessonId,
                                }}
                                handleClose={handleClose}
                              />
                            </Modal>
                          </div>
                        </>
                      ))}
                </Col>
              )}
            </Col>
            <Col
              md={isOpen && videoIsOpen ? 4 : videoIsOpen && !isOpen ? 5 : 0}
              className={
                !videoIsOpen ? "not-displayed" : "right-block displayed"
              }
              style={{ right: "0" }}
            >
              {" "}
              <Row>
                <LessonVideo url={data.video_url} />
              </Row>
              <Col className="text-center">
                {(!data.questions.length && data.next_lesson && (
                  <button
                    onClick={handleNextLesson}
                    className="lesson-button text-center mb-4"
                  >
                    {t("go_to_next_lesson")}
                  </button>
                )) ||
                  (!data.questions.length && !data.next_lesson && (
                    <>
                      <p className="text-center">{t("course_is_over")}</p>
                      <button
                        onClick={validateLesson}
                        className="lesson-button text-center mb-4"
                      >
                        {t("validate_back_to_profile")}
                      </button>
                    </>
                  ))}

                {(data.questions.length > 0 &&
                  (!getProgressLesson() ||
                    getProgressLesson().quizz_result === null) && (
                    <div className="row justify-content-center">
                      <p className="text-center">{t("need_to_answer_quizz")}</p>
                      <button
                        onClick={handleShow}
                        className="lesson-button text-center mb-4"
                      >
                        {t("do_quizz")}
                      </button>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        dialogClassName="quizz-modal"
                      >
                        <Modal.Header closeButton>{data.title}</Modal.Header>
                        <LessonQuizz
                          ids={{
                            course: courseId,
                            chapter: chapterId,
                            lesson: lessonId,
                          }}
                          handleClose={handleClose}
                        />
                      </Modal>
                    </div>
                  )) ||
                  (data.questions.length > 0 &&
                    getProgressLesson() &&
                    getProgressLesson().quizz_result !== null && (
                      <>
                        <div className="justify-content-center">
                          <div className="mr-1">
                            <p className="text-center">
                              {t("already_answered")}
                            </p>
                          </div>
                          <div>
                            <p className="text-center">
                              {t("result_obtained")} :{" "}
                              {getProgressLesson().quizz_result}
                            </p>
                          </div>
                          <div className="text-center">
                            <button
                              onClick={handleShow}
                              className="lesson-button text-center mb-4"
                            >
                              {t("redo_quizz")}
                            </button>
                          </div>

                          <Modal
                            show={show}
                            onHide={handleClose}
                            dialogClassName="quizz-modal"
                          >
                            <Modal.Header closeButton>
                              {data.title}
                            </Modal.Header>
                            <LessonQuizz
                              ids={{
                                course: courseId,
                                chapter: chapterId,
                                lesson: lessonId,
                              }}
                              handleClose={handleClose}
                            />
                          </Modal>
                        </div>
                      </>
                    ))}
              </Col>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default Lesson;
