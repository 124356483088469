import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Table, Row, Form, Button } from "react-bootstrap";
import {
  calculateWeekBeetweenTwoDates,
  insert,
  excludeWeekEndDates,
  createArrayWithFalseValuesDependingOnValidDates,
  filterAlreadyCompletedBootcampPresencesByMomentAndSortThem,
  compareExistingPresencesWithValidDates,
} from "helpers/presenceTableHelpers";
import "./PresenceTable.scss";

const PresenceTable = ({
  notFilteredExistantPresences,
  numberOfWeeks,
  setNumberOfWeeks,
  checkedStateForMorning,
  setCheckedStateForMorning,
  checkedStateForAfternoon,
  setCheckedStateForAfternoon,
  handleNewPresence,
  handleDeletePresence,
  selectedUser,
  dateToWorkWith,
  setDateToWorkWith,
  validDatesOnWeek,
  setValidDatesOnWeek,
  getAlreadyCompletedBootcampPresences,
  activeBootcamp,
  handleCompletePresences,
}) => {
  const { t } = useTranslation("profile");

  const handleOnChange = (position, i, state, setState, isMorning) => {
    const updateArray = (value, array) => {
      const newArrayValue = [...array];
      newArrayValue[i] = value;
      return newArrayValue;
    };

    const updateCheckedState = state.map((array, index) => {
      position === index
        ? setDateToWorkWith({
            date: validDatesOnWeek[index][i],
            morning: isMorning === "morning" ? true : false,
          })
        : null;
      return position === index ? updateArray(!array[i], array) : array;
    });

    setState(updateCheckedState);
  };

  const checkIfDateAlreadyExists = (date) => {
    return notFilteredExistantPresences.some(
      (presence) =>
        new Date(presence.date).toString() === new Date(date.date).toString() &&
        presence.morning === date.morning
    );
  };

  useEffect(() => {
    if (activeBootcamp) {
      const { start_date, end_date } = activeBootcamp;
      setNumberOfWeeks(
        calculateWeekBeetweenTwoDates(new Date(start_date), new Date(end_date))
      );
      excludeWeekEndDates(
        new Date(start_date),
        new Date(end_date),
        setValidDatesOnWeek
      );
      getAlreadyCompletedBootcampPresences();
    }
  }, [activeBootcamp]);

  useEffect(() => {
    if (notFilteredExistantPresences) {
      const { morningPresences, afternoonPresences } =
        filterAlreadyCompletedBootcampPresencesByMomentAndSortThem(
          notFilteredExistantPresences
        );

      const morningPresencesFilled =
        createArrayWithFalseValuesDependingOnValidDates(validDatesOnWeek);
      const afternoonPresencesFilled =
        createArrayWithFalseValuesDependingOnValidDates(validDatesOnWeek);
      const updateCheckedStateForMorning =
        compareExistingPresencesWithValidDates(
          morningPresences,
          validDatesOnWeek,
          morningPresencesFilled
        );
      const updateCheckedStateForAfternoon =
        compareExistingPresencesWithValidDates(
          afternoonPresences,
          validDatesOnWeek,
          afternoonPresencesFilled
        );

      setCheckedStateForMorning(updateCheckedStateForMorning);
      setCheckedStateForAfternoon(updateCheckedStateForAfternoon);
    }
  }, [notFilteredExistantPresences]);

  useEffect(() => {
    if (dateToWorkWith) {
      const toPostOrToDelete = checkIfDateAlreadyExists(dateToWorkWith);
      if (toPostOrToDelete === false) {
        handleNewPresence();
      }

      if (toPostOrToDelete === true) {
        handleDeletePresence();
      }
    }
  }, [dateToWorkWith]);

  useEffect(() => {
    if (validDatesOnWeek) {
      const newCheckedState =
        createArrayWithFalseValuesDependingOnValidDates(validDatesOnWeek);

      setCheckedStateForMorning(newCheckedState);
      setCheckedStateForAfternoon(newCheckedState);
    }
  }, [validDatesOnWeek]);

  const TableHeader = () => {
    const columns = [];
    for (let i = 1; i <= numberOfWeeks; i++) {
      columns.push({ headerName: `${t("week")} ${i}`, field: `week${i}` });
    }

    const finalColumns = insert(columns, 0, { headerName: " ", field: " " });

    return (
      <thead style={{ textAlign: "center" }}>
        <tr>
          {finalColumns.map((column, index) => {
            if (index === 0) {
              return <th key={index}>{column.headerName}</th>;
            }
            return (
              <th style={{ borderWidth: 1, borderStyle: "solid" }} key={index}>
                <div>{column.headerName}</div>
                <div
                  style={{
                    width: "50%",
                    float: "left",
                    borderWidth: 0,
                    borderStyle: "dashed",
                    borderRightWidth: 1,
                    fontSize: 10,
                  }}
                  className="pt-2"
                >
                  {t("morning")}
                </div>
                <div
                  style={{ width: "50%", float: "right", fontSize: 11.5 }}
                  className="pt-2"
                >
                  {t("afternoon")}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const TableBody = () => {
    const days = [
      t("monday"),
      t("tuesday"),
      t("wednesday"),
      t("thursday"),
      t("friday"),
    ];
    const columns = [];
    for (let i = 1; i <= numberOfWeeks; i++) {
      columns.push({ headerName: `Semaine ${i}`, field: `week${i}` });
    }

    const finalColumns = insert(columns, 0, { headerName: " ", field: " " });

    return (
      <tbody style={{ textAlign: "center" }}>
        {days.map((day, i) => (
          <tr key={i}>
            <th scope="row">
              <p>{day}</p>
            </th>
            {finalColumns.map((column, index) => {
              if (index === 0) return null;
              return (
                <th scope="row" key={index}>
                  <div
                    style={{
                      width: "50%",
                      float: "left",
                      borderWidth: 0,
                      borderStyle: "dashed",
                      borderRightWidth: 1,
                      fontSize: 10,
                    }}
                    className="pt-2"
                  >
                    <Form.Check
                      type="checkbox"
                      name={validDatesOnWeek[index - 1][i]}
                      value={validDatesOnWeek[index - 1][i]}
                      checked={checkedStateForMorning[index - 1][i]}
                      onChange={() =>
                        handleOnChange(
                          index - 1,
                          i,
                          checkedStateForMorning,
                          setCheckedStateForMorning,
                          "morning"
                        )
                      }
                    />
                  </div>
                  <div
                    style={{ width: "50%", float: "right", fontSize: 11.5 }}
                    className="pt-2"
                  >
                    <Form.Check
                      type="checkbox"
                      name={validDatesOnWeek[index - 1][i]}
                      value={validDatesOnWeek[index - 1][i]}
                      checked={checkedStateForAfternoon[index - 1][i]}
                      onChange={() =>
                        handleOnChange(
                          index - 1,
                          i,
                          checkedStateForAfternoon,
                          setCheckedStateForAfternoon,
                          "afternoon"
                        )
                      }
                    />
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="d-flex flex-column">
      {checkedStateForMorning?.length > 0 &&
        checkedStateForAfternoon?.length > 0 && (
          <Table
            striped
            bordered
            hover
            responsive
            className="my-4 presences-table"
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}
      <div className="d-flex flex-row justify-content-center align-items-center">
        <Button
          type="submit"
          className="ButtonPrimary my-3"
          onClick={handleCompletePresences}
        >
          {t("admin:add_all_presences")}
        </Button>
      </div>
    </div>
  );
};

export default PresenceTable;
