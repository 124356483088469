import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import PresenceTable from "components/PresenceTable/PresenceTable";
import { useLocation } from "react-router";

const EditSelectedUserPresences = () => {
  const { t } = useTranslation("admin");
  const { pathname } = useLocation();
  const { get, post, destroy } = useFetch();
  const [selectedUserId, setSelectedUserId] = useState(pathname.split("/")[5]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeBootcampId, setActiveBootcampId] = useState(null);
  const [selectedBootcamp, setSelectedBootcamp] = useState(null);
  const [numberOfWeeks, setNumberOfWeeks] = useState(null);
  const [validDatesOnWeek, setValidDatesOnWeek] = useState([]);
  const [checkedStateForMorning, setCheckedStateForMorning] = useState(null);
  const [checkedStateForAfternoon, setCheckedStateForAfternoon] =
    useState(null);
  const [notFilteredExistantPresences, setNotFilteredExistantPresences] =
    useState(null);
  const [dateToWorkWith, setDateToWorkWith] = useState(null);

  const getSelectedUser = async () => {
    const response = await get(`/admin/users/${selectedUserId}`);
    setSelectedUser(response);
  };

  const getBootcamp = async () => {
    const response = await get(
      `/admin/bootcamp_sessions?company_id=${selectedUser.company_id}`
    );
    setSelectedBootcamp(response);
    setActiveBootcampId(response.id);
  };

  const getAlreadyCompletedBootcampPresencesForSelectedUser = async () => {
    const response = await get(
      `/admin/bootcamp_presences?bootcamp_session_id=${activeBootcampId}&user_id=${selectedUserId}`
    );
    setNotFilteredExistantPresences(response);
  };

  const handleNewPresence = async () => {
    const data = {
      bootcamp_session_id: activeBootcampId,
      date: dateToWorkWith.date,
      morning: dateToWorkWith.morning,
      user_id: selectedUserId,
    };
    const response = await post("/admin/bootcamp_presences", data);
    setDateToWorkWith(null);
    setNotFilteredExistantPresences([
      ...notFilteredExistantPresences,
      response,
    ]);
  };

  const handleDeletePresence = async () => {
    if (dateToWorkWith) {
      const dateToDeleteId = notFilteredExistantPresences.find(
        (presence) =>
          new Date(presence.date).toString() ===
            new Date(dateToWorkWith.date).toString() &&
          presence.morning === dateToWorkWith.morning
      ).id;
      if (dateToDeleteId) {
        const response = await destroy(
          `/admin/bootcamp_presences/${dateToDeleteId}`,
          null,
          false
        );

        setDateToWorkWith(null);
        setNotFilteredExistantPresences(
          notFilteredExistantPresences.filter(
            (presence) => presence.id !== dateToDeleteId
          )
        );
      }
    }
  };

  const handleCompletePresences = async () => {
    if (!validDatesOnWeek || validDatesOnWeek.length === 0) return;
    
    const presences = validDatesOnWeek.flat().flatMap(date => [
      { date, morning: true },
      { date, morning: false }  
    ]);

    const data = {
      bootcamp_session_id: activeBootcampId,
      user_id: selectedUserId,
      presences
    };

    try {
      const response = await post("/admin/bootcamp_presences/complete_presences", data);
  
      setNotFilteredExistantPresences(response);
      console.log("Présences ajoutées avec succès :", response);
    } catch (error) {
      console.error("Erreur lors de l'ajout des présences :", error);
    }
    
  };
  

  useEffect(() => {
    getSelectedUser();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      getBootcamp();
    }
  }, [selectedUser]);

  useEffect(() => {
    getAlreadyCompletedBootcampPresencesForSelectedUser();
  }, [activeBootcampId]);


  return (
    <div>
      <h3 className="text-center mb-4">{t("selected_user_presences")} </h3>
      <PresenceTable
        notFilteredExistantPresences={notFilteredExistantPresences}
        numberOfWeeks={numberOfWeeks}
        setNumberOfWeeks={setNumberOfWeeks}
        checkedStateForMorning={checkedStateForMorning}
        setCheckedStateForMorning={setCheckedStateForMorning}
        checkedStateForAfternoon={checkedStateForAfternoon}
        setCheckedStateForAfternoon={setCheckedStateForAfternoon}
        handleNewPresence={handleNewPresence}
        handleDeletePresence={handleDeletePresence}
        selectedUser={selectedUserId}
        dateToWorkWith={dateToWorkWith}
        setDateToWorkWith={setDateToWorkWith}
        validDatesOnWeek={validDatesOnWeek}
        setValidDatesOnWeek={setValidDatesOnWeek}
        getAlreadyCompletedBootcampPresences={
          getAlreadyCompletedBootcampPresencesForSelectedUser
        }
        activeBootcamp={selectedBootcamp}
        handleCompletePresences={handleCompletePresences}
      />
    </div>
  );
};

export default EditSelectedUserPresences;
