import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import LittleCourseCard from "components/littleCourseCard/LittleCourseCard";

const MyCourses = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { get, isLoading } = useFetch();
  const [userCourses, setUserCourses] = useState([]);
  const { t } = useTranslation();

  const getAllCurrentUserSubscriptions = async () => {
    const response = await get(`/users/${currentUser.id}/subscriptions`);
    if (response) {
      const allCourses = response.flatMap((sub) => sub.learning_path.courses);

      const uniqueCourses = Array.from(new Map(allCourses.map(course => [course.id, course])).values());

      setUserCourses(uniqueCourses);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getAllCurrentUserSubscriptions();
    }
  }, [currentUser]);

  return (
    <div className="MyCourses">
      <div className="page-title-section text-center">
        <h4 className="page-title">{t("navigation:my_courses")}</h4>
      </div>
      <Row className="d-flex justify-content-center">
        {userCourses.length > 0 &&
          userCourses.map((course) => (
            <LittleCourseCard key={course.id} course={course} />
          ))}
        {!isLoading && userCourses.length === 0 && (
          <p className="text-center">{t("no_subscriptions")}</p>
        )}
      </Row>
    </div>
  );
};

export default MyCourses;
